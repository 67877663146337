html, body {
    overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    margin: 0 auto;
    padding: 0 auto;
}

#home-banner {

    height: 500px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
}

#home-banner-content {
    display: flex;
    flex-direction: row;
    height: 500px;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px;
    text-align: center;
    background-color: #79227b;
}

.home-image-main {
    height: 400px;
    margin: 0px;
    padding: 0px;
}

#home-banner-description {
    width: 45%;
    font-size: 20px;
}

#home-banner-title {
    width: 25%;
    font-size: 25px;
    line-height: 50px;
}

.home-button-main {
    padding: 10px;
    font-size: 20px;
    border-radius: 10%;
    height: 60px;
    border-radius: 15px 15px 15px 15px;
    width: 150px;
}

.home-button-main:hover {
    font-weight: 500;
    cursor: pointer;
    background-color: black;
    color: white;
}

.featured-container {
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.featured-ind-container {
    background-color: lightgray;
    text-align: center;
    padding: 5px;
    color: black;
    height: auto;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featured-image {
    height: 225px;
    width: 150px;
    margin-bottom: 5px;
    box-shadow: 2px 2px 10px black;
}

.featured-ind-heading {
    height: 100px;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.featured-button {
    padding: 10px;
    font-size: 17px;
    border-radius: 15px 15px 15px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.featured-button:hover {
    color: white;
    background-color: #79227b;
    cursor: pointer;
}

.price-field {
    font-weight: 400;
}

#newsletter {
    margin-top: 75px;
    text-align: center;
}

.mailingList-input {
    width: 50vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 25px;
    padding: 10px;
    border: 2px solid #79227b;
    letter-spacing: 1px;
    font-size: 20px;
    text-align: center;
}

.subscribe-button {
    color: white;
    background-color: #79227b;
    padding: 10px;
    margin-top: 30px;
    font-size: 20px;
    border-radius: 15px 15px 15px 15px;
    width: 200px;
}

.subscribe-button:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

#home-banner-right-arrow {
    width: 5%;
    margin-right: 0;
    padding-right: 0;
}

#home-banner-left-arrow {
    width: 5%;
    margin-right: 0;
    padding-right: 0;
}

#right-arrow {
    color: white;
}

#right-arrow:hover {
    cursor: pointer;
}

#left-arrow {
    color: white;
}

#left-arrow:hover {
    cursor: pointer;
}

#ind-banner-right-arrow {
    width: 5%;
    margin-right: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
}

#ind-banner-left-arrow {
    width: 5%;
    margin-right: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
}

#right-arrow-ind {
    color: black;
}

#left-arrow-ind {
    color: black;
}

#right-arrow-ind:hover {
    cursor: pointer;
}

#left-arrow-ind:hover {
    cursor: pointer;
}

#featured-heading-start {
    color: black;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 500;
    font-size: 30px;
    letter-spacing: 1px;
}

#featured-heading {
    color: black;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 500;
    font-size: 30px;
    letter-spacing: 1px;
}

#newsletter-heading {
    color: black;
}



@media screen and (max-width: 1200px) {
    
    #home-banner-description {
        width: 45%;
        font-size: 17px;
    }

    #home-banner-title {
        font-size: 17px;
    }

    .home-image-main {
        height: 300px;
    }

    .featured-ind-heading {
        height: 200px;
        font-size: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .featured-image {
        height: 200px;
        width: 150px;
        margin-bottom: 20px;
    }

    .featured-ind-container {
        background-color: lightgray;
        width: 80%;
        text-align: center;
        color: black;
        padding: 15px;
        margin: 0;
        height: 500px;
        align-items: center;
        justify-content:space-evenly;
    }

    .featured-container {
        padding: 10px;
        align-items: center;
    }

 
}


@media screen and (max-width: 975px) {
    .featured-container {
        display: flex;
        flex-direction: column; /* Change to row for horizontal layout */
        padding: 5px;
        height: auto;
        justify-content: center;
        align-items: center;
    }

    .featured-ind-container {
        background-color: lightgray;
        width: 80%;
        text-align: center;
        color: black;
        padding: 0px;
        margin: 0;
        height: auto;
    }

    

    #home-banner-content {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px;
        text-align: center;
        background-color: #79227b;
    }
    
    .home-image-main {
        height: 225px;
        margin: 0px;
        padding: 0px;

    }
    
    #home-banner-description {
        width: 100%;
        font-size: 15px;
    }
    
    #home-banner-title {
        width: 100%;
        font-size: 15px;
        line-height: 25px;
    }
    
    .home-button-main {
        padding: 5px;
        font-size: 15px;
        border-radius: 15px 15px 15px 15px;
        height: 40px;
     
    }

    #home-banner {
        height: auto;
    }

    #newsletter {
        margin-top: 50px;
        text-align: center;
    }

    #right-arrow {
        display: none;
    }

    #left-arrow {
        display: none;
    }

    #right-arrow-ind {
        display: none;
    }
    
    #left-arrow-ind {
        display: none;
    }

    .featured-ind-heading {
        height: auto;
        font-size: 15px;
        padding: 15px;
        width: 80%;
        text-wrap: wrap;
        
    }

    .featured-image {
        height: 225px;
        width: 150px;
        margin-bottom: 20px;
    }

    .mailingList-input {
        width: 80vw;
    }

    .featured-button {
        margin: 0px;
        margin-bottom: 10px;
    }

    #featured-heading-start {
        margin-top: 50px;
        letter-spacing: 0px;

    }
}

