#admin-dash-container {
    text-align: center;
    height: auto;

}

#home-page-edit-container {
    text-align: center;
}

.edit-title-banner {
    width: 100vw;
    background-color: #79227b;
    color: white;
    margin-top: 0px;
    padding-top: 0px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.home-edit-form {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.home-edit-content-container {
    border: 5px solid gray;
    padding: 10px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px 10px 10px 10px;
    color: white;
}

.home-edit-field {
    font-size: 18px;
    padding: 5px;
    margin: 10px;
}

#home-edit-field-description {
    text-wrap: wrap;
    height: 200px;
}

.home-button-save {
    padding: 10px;
    font-size: 20px;
    margin: 10px;
    background-color: #79227b ;
    color: white;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.home-button-save:hover {
    background-color: green;
    cursor: pointer;
}

.home-edit-form-ind {
    width: 30%;
    text-align: center;
    padding: 5px;
    color: black;
    display: flex;
    flex-direction: column;

}

.home-edit-form-ind-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.home-edit-field-ind {
    font-size: 18px;
    padding: 5px;
    margin: 10px;
}

.add-book-form {
    display: flex;
    flex-direction: column;
    border: 5px solid gray;
    padding: 15px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px 10px 10px 10px;
}

.add-book-input {
    font-size: 17px;
    padding: 5px;
}

#edit-ind-home-container-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

#featured-container-edit {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
}

.featured-button-edit {
    padding: 10px;
    font-size: 17px;
    border-radius: 15px 15px 15px 15px;
    margin: 10px;
}

.featured-button-edit:hover {
    cursor: pointer;
    background-color: black;
    color: white;
}

#trash-edit-admin:hover {
    background-color: red;
    color: white;
}

.home-edit-content-container-ind {
    border: 5px solid gray;
    padding: 10px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px 10px 10px 10px;
}

.featured-ind-container-edit {
    background-color: lightgray;
    text-align: center;
    padding: 5px;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 5px;
}

.featured-image-edit {
    height: 150px;
}

.featured-ind-heading-edit {
    height: 50px;
    font-size: 17px;
    width: 30%;
}

#books-admin-dash-container {
    display: flex;
    flex-direction: column;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.featured-ind-container-edit-admin {
    background-color: lightgray;
    text-align: center;
    padding: 15px;
    color: black;
    display: flex;
    align-items: center;
    margin: 5px;
    justify-content: space-evenly;
    border-radius: 10px 10px 10px 10px;
}

.ind-book-details-admin {
    background-color: #79227b;
    color: white;
    border-radius: 10px 10px 10px 10px;
    font-size: 18px;
    padding: 10px;
}

.ind-book-details-admin:hover {
    cursor: pointer;
    background-color: black;
    color: white;
}

@media screen and (max-width: 800px) {
    .featured-ind-container-edit-admin {
        flex-direction: column;
        height: auto;
        justify-content: space-evenly;
    }

    #books-admin-dash-container {
        height: auto;
        width: 90vw;
    }

    .featured-ind-heading-edit {
        width: auto;
        text-align: center;
        height: auto;
    }

    .featured-ind-container-edit {
        flex-direction: column;
    }

    #featured-container-edit {
        height: auto;
    }
    
}

