#contact-form {
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.contact-input {
    font-size: 20px;
    height: 20px;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    width: 80vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
}

.contact-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    padding: 10px;
    font-size: 20px;
    background-color: #79227b;
    color: white;
    border-radius: 10px 10px 10px 10px;
    width: 150px;
}

.contact-button:hover {
    cursor: pointer;
    background-color: black;
}

#state-dropdown {
    font-size: 20px;
    height: 50px;
    border-radius: 5px;
    width: 90vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid black;
    max-width: 425px;
    color: gray;
    padding: 10px;
}

#message-contact {
    border: 2px solid black;
}

