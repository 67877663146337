#ind-detail-container {
    text-align: center;
}

.ind-detail-table {
    text-align: left;
    text-wrap: wrap;
    width: 70vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(228, 228, 228);
    padding: 25px;
    border-radius: 10px 10px 10px 10px;
}

.ind-detail-title {
    width: 100vw;
    background-color: #79227b;
    color: white;
    margin-top: 0px;
    padding-top: 0px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.ind-book-cart-detail {
    width: 150px;
    height: 60px;
    background-color: #79227b;
    color: white;
    border-radius: 10px 10px 10px 10px;
    font-size: 18px;
    margin-top: 15px;
}

.ind-book-cart-detail:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.ind-detail-table {
    border: 2px solid darkgray;
}

.ind-book-price-actual-detail {
    border: 3px solid #79227b;
    padding: 7px;
    width: 150px;
    text-align: center;
    font-weight: 400;
    background-color: rgb(227, 227, 227);
    font-size: 17px;
    margin-top: 15px;
}

#ind-book-detail-price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

#qty-price-container {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: center;
}

.qty-button {
    text-align: center;
    width: 100px;
    font-size: 18px;
    margin-left: 10px;
    margin-right: 25px;
    border: 3px solid #79227b;
    background-color: rgb(227, 227, 227);
}

.ind-book-image-detail {
    height: 300px;
    width: auto;
    margin-bottom: 15px;
}


@media screen and (max-width: 800px) {
    .ind-detail-table {
        width: 95vw;
        font-size: 15px;
    }

    .ind-detail-title {
        height: auto;
        font-size: 20px;
    }

    .ind-book-image-detail {
        height: 200px;
        width: auto;
        margin-bottom: 15px;
    }


}