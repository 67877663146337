html {
    overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    margin: 0 auto;
    padding: 0 auto;
    width: 100%;
}

body, html {
    margin: 0;
    padding: 0;
}