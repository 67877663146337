
#footer-container {
    text-align: center;
    background-color: gray;
    color: white;
    padding: 5px;
    margin: 0; /* Remove default margin */
    width: 100%; /* Make it cover the entire width */
    margin-top: 50px;
    padding-bottom: 35px;
    line-height: 12px;
}

.login-link {
    color: white;
}

.login-link:hover {
    cursor: pointer;
    color: #79227b;
}

@media screen and (max-width: 800px) {
    #footer-container {
        padding-top: 15px;
        font-size: 15px;
    }

    .logo {
        line-height: 30px;
    }

    #footer-lower-container {
        font-size: 12px;
    }
}
