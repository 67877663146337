html {
    overflow-x: hidden;
}

* {
    font-family: 'Poppins', sans-serif;;
}

#header {
    text-align: center;
    line-height: 30px;
    letter-spacing: 1px;
}

.logo-caption {
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0px;
    padding-bottom: 0px;
}

.logo-line {
    background-color: #79227b;
    color: #79227b;
    height: 2px;
    width: 250px;
    padding: 0;
    border: none;
    line-height: 0px;
}

.logo {
    margin-top: 50px;
    font-weight: 400;
}

a {
    text-decoration: none;
    text-emphasis: none;
    text-transform: none;
    color: black;
}

a:hover {
    cursor: pointer;

}

#links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav-link {
    font-weight: 400;
    padding-left: 17px;
    padding-right: 17px;
    text-align: center;
    transition: font-weight 0.3s;
}

.nav-link:hover,
.nav-link:active {
    font-weight: 500;
}

#cart {
    color: #79227b;
}

.search-bar {
    width: 70vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 25px;
    padding: 10px;
    border: 2px solid gray;
    margin-right: 0px;
    font-size: 15px;
}

#input {
    display: inline-flex;
    align-items: center;
    transform: translate(10%);
    margin-bottom: 30px;
}

#search-glass {
    margin: 0;
    padding-left: 15px;
}

#search-glass:hover {
    cursor: pointer;
    color:#79227b;
}

#select-search-input {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 10px;
    border: 2px solid gray;
    margin-right: 0px;
    font-size: 16px;
}

@media screen and (max-width: 900px) {
    .nav-link {
        font-size: 15px;
        margin: 5px;
    }

    #contact-us {
        display: none;
    }

    .search-bar {
        width: 60vw;
        font-size: 13px;
        margin-top: 10px;
    }

    #links {
        flex-direction: column;
    }

    #select-search-input {
        display: none;
    }

    #input {
        display: inline-flex;
        align-items: center;
        transform: translate(20%);
    }

    a {
        padding-top: 5px;
    }

    #cart {
        margin-bottom: 25px;
    }

    .logo {
        font-size: 30px;
        margin: 25px;
    }

    #header {
        line-height: 10px;
    }
}
