#login-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 275px;
}

#login-heading {
    text-align: center;
    margin-bottom: 0px;
    margin-top: 50px;
}

.login-input {
    font-size: 20px;
    height: 20px;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    width: 80vw;
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-button {
    padding: 10px;
    font-size: 20px;
    margin: 10px;
    background-color: #79227b ;
    color: white;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-button:hover {
    background-color: #4f1650 ;
    cursor: pointer;
}

.home-button-login {
    border: 2px solid black;
    padding: 5px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    font-size: 20px;
}

.home-button-login:hover {
    background-color: black;
    color: white;
}