
#books-heading {
    width: 100vw;
    background-color: #79227b;
    color: white;
    margin-top: 0px;
    padding-top: 0px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .5px;
}

#ind-book-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: auto;
    background-color: lightgray;
    padding: 15px;
    margin: 15px;
    border: 2px solid darkgray;
    border-radius: 5px 5px 5px 5px;
    box-sizing: border-box;
    width: 90vw;
}

.ind-book-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ind-book-image {
    height: auto;
    width: 125px;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 3px 3px 10px black;
}

.ind-book-details {
    width: 110px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    background-color: #79227b;
    color: white;
    border-radius: 10px 10px 10px 10px;
    font-size: 18px;
}

.ind-book-title {
    font-weight: 800;
    font-size: 18px;
    width: 500px;
    text-align: center;
}

.ind-book-price {
    font-weight: 400;
}

.ind-price-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.ind-book-price-actual {
    margin-left: 15px;
    border: 3px solid #79227b;
    padding: 7px;
    width: 150px;
    text-align: center;
    font-weight: 400;
    background-color: lightgray;
    font-size: 17px;
    margin-right: 20px;
}


.ind-book-cart {
    width: 150px;
    height: 60px;
    background-color: #79227b;
    color: white;
    border-radius: 10px 10px 10px 10px;
    font-size: 18px;

}

.ind-book-cart:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

.ind-book-details:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

#all-books-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom-total-pages-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    gap: 10px;
}

button.page-link {
    padding: 10px;
    background-color: #79227b;
    color: white;
    border-radius: 10px 10px 10px 10px;
    font-size: 17px;
}

button.page-link:hover {
    cursor: pointer;
}

h3.total-pages {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.ind-book-button-container {
    display: flex;
    flex-direction: row;
}

#ind-form-books {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#loading-books {
    text-align: center;
}

.books-heading-h1 {
    text-align: center;
}

.loading-dots {
    display: inline-block;
    animation: loading-animation 1s infinite;
    letter-spacing: 5px;
    margin-left: 5px;
}

.loading-dots::after {
    content: '';
    display: inline-block;
    width: 1em;
    /* Adjust the width as needed */
    text-align: left;
    /* Align dots to the left of the container */
    animation: loading-animation 1s infinite;
}

#loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#gear {
    color: #79227b;
    margin-right: 15px;
}

#select-add-featured {
    text-align: center;
    border-radius: 10px 10px 10px 10px;

}

@keyframes loading-animation {

    0%,
    100% {
        content: '';
    }

    25% {
        content: '.';
    }

    50% {
        content: '..';
    }

    75% {
        content: '...';
    }
}

@media screen and (max-width: 1150px) {
    .ind-book-title {
        width: auto;
        font-size: 17px;
        padding: 15px;
    }

    #ind-form-books {
        flex-direction: column;
        margin: 10px;
    }

    .ind-book-cart {
        width: 150px;
        height: 50px;
        background-color: #79227b;
        color: white;
        border-radius: 10px 10px 10px 10px;
        font-size: 18px;
        margin: 15px;
    }

    .ind-book-details {
        width: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
        background-color: #79227b;
        color: white;
        border-radius: 10px 10px 10px 10px;
        font-size: 15px;
    }

}

@media screen and (max-width: 800px) {
    #ind-book-container {
        flex-direction: column;
        height: auto;
        align-items: center;
        width: 85vw;
    }

    .ind-price-container {
        flex-direction: column;
        align-items: center;
    }

    .ind-book-price-actual {
        padding: 5px;
        margin: 0;
    }



    .ind-book-title {
        width: auto;
        padding-left: 5px;
        padding-right: 5px;
    }

    .ind-book-cart {
        width: 150px;
        height: 50px;
        background-color: #79227b;
        color: white;
        border-radius: 10px 10px 10px 10px;
        font-size: 18px;
        margin: 15px;
    }

    #ind-form-books {
        flex-direction: column;
        margin: 10px;
    }

    .ind-book-image {
        height: auto;
        width: 100px;
    }

    #books-heading {
        height: auto;
    }

    .ind-book-price {
        padding: 0;
        margin: 0;
    }
}